.body{
  height: 100%;
  width: 100%;
}

html {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

/* Landing Page */
.spac {
  height: 10px;
  display: block;
  margin-bottom: 30px;
}

.col1{
  width: 29.5%;
  float: left;
  border-left: .5px solid black;
  border-right: .5px solid black;
}

.col2{ 
  width: 40.2%;
  float: left;
  max-height: 70%;
}
.col3{
  width: calc(30.15% - 2px);
  float: right;
  border-left: .5px solid black;
  border-right: .5px solid black;
  max-height: 70%;
  margin-top: 0px;
  height: 1249px;
}

.col4{
  width: calc(70.4% - 2px);
  float: right;
  border-top: .5px solid black;
  border-right: .5px solid black;
}

.pic1{
  height: 70%;
  width: 70%;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 10%;
}

.pic2{
  height: 90%;
  width: 90%;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 10%;
}

.pic3{
  height: 80%;
  width: 70%;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 5%;
}

.pic4{
  height: 80%;
  width: 80%;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 15%;
}

.pic5{
  height: 80%;
  width: 80%;
  padding-left: 10%;
  padding-top: 10%;
  padding-right: 5%;
}

.pic6{
  height: 52%;
  width: 52%;
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 15%;
}

.pic7{
  height: 70%;
  width: 70%;
  padding-left: 25%;
  padding-top: 2%;
}

.pic8{
  height: 50%;
  width: 50%;
  padding-left: 10%;
  padding-top: 10%;
  padding-right: 5%;
}

.small-box{
  height: 50px;
  border-bottom: .5px solid black;
}

.medium-box{
  height: 85px;
  border-bottom: .5px solid black;
}

.vertical-box{
  height: 100%;
  width: 50px;
  border: .5px solid black;
}

.pic-container{
  height: 40%;
  border-bottom: .5px solid black;
}

.pic-container-no-bottom{
  height: 40%;
  border-bottom: none;
}

.caption1{
  padding-bottom: 50%;
  font-size: 11px;
  margin-left: 5%;
  margin-right: 11%;
  font-weight: 100;
  text-transform: uppercase;
  font-size: x-small;
  color: black;
  font-weight: 350;
}
.caption2{
  padding-bottom: 20%;
  font-size: 11px;
  margin-left: 5%;
  font-weight: 350;
}
.caption3{
  padding-bottom: 20%;
  font-size: 11px;
  margin-left: 5%;
  font-weight: 350;
}
.caption4{
  padding-bottom: 20%;
  font-size: 11px;
  margin-left: 5%;
  font-weight: 350;
}
.caption5{
  padding-bottom: 30%;
  font-size: 11px;
  margin-left: 10%;
  font-weight: 350;
}
.caption6{
  padding-bottom: 20%;
  font-size: 11px;
  margin-left: 5%;
  font-weight: 350;
}
.caption7{
  padding-bottom: 4.5%;
  font-size: 11px;
  margin-left: 25%;
  font-weight: 350;
}
.caption8{
  padding-bottom: 30%;
  font-size: 11px;
  margin-left: 10%;
  font-weight: 350;
}



/* Header Section */

.navbar {
  border-top: solid black 1px;
  border-bottom: solid black 1px;
  margin: 0 auto;
  padding: 0px;
  height: 45px;
  margin-top: 0px;
  display: flex;
}

.header-logo {
  margin: 0 auto;
  display: inline-block;
  padding: 0px;
  width: 200px;
  margin-top: 3px;
}

.navigate {
  margin: 0 auto;
  padding: 0px;
  display: inline-block;
  width: 450px;
  /* height: 30px; */
  margin-left: 56.5%;
  margin-top: 0px;
}

.navigate-two {
  margin: 0 auto;
  padding: 0px;
  display: inline-block;
  align-items: baseline;
  
}

.nav-item {
  margin: 0 auto;
  width: 120px;
  text-transform: uppercase;
  /* font-size: small; */
  display: inline-block;
  border-left: solid black 1px;
  height: 45px;
  align-items: center;
  margin-top: 0px;
  text-align: center;
  justify-content: center;
  font-size: x-small;
  text-decoration: none;
}

.menu-item {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 15px;
  text-decoration: none;
}

a {
  color: black;
}

img {
  height: 40px;
  padding-left: 56px;
}



/* Footer Section */
.footer-container {
  border-top: 1px solid black;
  padding: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: x-small;
  background-color: white;
}



/* Project and Work page image sizes */
.tiny {
  width: 350px;
  height: 410px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.smallest {
  width: 350px;
  height: 437px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.smish {
  width: 350px;
  height: 490px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.sm {
  width: 350px;
  height: 525px;
  /* width: 75%;
  height: 90%; */
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
  /* border: solid black 1px; */
}
.work-sm {
  width: 350px;
  height: 525px;
  /* width: 75%;
  height: 90%; */
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
  /* border: solid black 1px; */
}


.smed {
  width: 375px;
  height: 468px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.justoversmed {
  width: 375px;
  height: 489px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.premidjust {
  width: 375px;
  height: 501px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.midjust {
  width: 375px;
  height: 517px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.justundermed {
  width: 400px;
  height: 443px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.med {
  width: 400px;
  height: 534px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.medi {
  width: 450px;
  height: 554px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.medium {
  width: 450px;
  height: 563px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.sml {
  width: 540px;
  height: 532px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.sqr {
  width: 600px;
  height: 600px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.ml {
  width: 620px;
  height: 492px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.lg {
  width: 700px;
  height: 466px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.unknown {
  width: 750px;
  height: 491px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
} 

.xl {
  width: 800px;
  height: 456px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.xxl {
  width: 850px;
  height: 567px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.xxlarge {
  width: 850px;
  height: 617px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.xxxl {
  width: 850px;
  height: 642px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}

.biggest {
  width: 875px;
  height: 442px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}



/* Work Page and Project Page arrangement*/

.used-project {
  text-align: center;
  align-items: center;
}

.work-caption {
  width: 390px;
  text-align: center;
}

.links {
  display: inline;
  text-align: center;
}

.App-link {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}

.work1to3 {
  margin: 0 auto;
  display: flex;
  width: inherit;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
}


.box1 {
  margin: 0 auto;
  width: 1260px;
  text-align: center;
}

.stilly-proj-pic {
  display: inline;
}

#bottom-pic {
  padding-bottom: 130px;
}

/* About Section */

.about-page {
  text-align: center;
  display: flex;
  width: 1250px;
  justify-content: center;
}

.about-pic {
  width: 550px;
  padding-right: 20px;
}

.bio {
  width: 400px;
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
}

/* Contact Page Form */
.container {
  margin-left: 10px;
  width: 600px;
}
.contact {
  font-size: 19px;
  letter-spacing: 0.05rem;
  margin-left: 10px;
  margin-top: 20px;
}

.ball-image {
  display: inline;
}

form {
  margin-top: 30px;
}

.singleItem label {
  display: block;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 20px;
}

.singleItem input {
  width: 422px;
  height: 30px;
  margin: 10px;
  margin-top: 5px;
  border: solid gray 1px;
  background-color: rgb(248, 245, 245);
}

#name {
  width: 200px;
  height: 30px;
  margin: 10px;
  margin-top: 5px;
}

#lastname {
  width: 200px;
  height: 30px;
  margin: 10px;
  margin-top: 5px;
}

.message {
  margin: 10px;
  margin-top: 5px;
  background-color: rgb(248, 245, 245);
}

.btn button{
  border: 2px solid black;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05rem;
  padding: 10px 35px 10px 35px;
  margin-left: 10px;
}

.btn button:hover {
  color: white;
  background-color: black;
}

.msg {
  font-style: italic;
  display: none;
}
.msgAppear {
  display: block;
}

.wholecontact {
  display: flex;
}

.blueball {
  margin-top: 100px;
  display: flex;
  margin-left: -12%;
  width: 450px;
  height: 300px;
}




/* Project Footer */
.proj-foot-div {
  border-top: 1px solid black;
  padding: none;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: black;
  position: fixed;
  display: block;
}


.opened {
  background-color: black;
  color: white;
  cursor: pointer;
  width: 100%;
  height: 370px;
  border: none;
  outline: none;
  font-size: 15px;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: auto;
}

.closed {
  width: 100%;
  background-color: black; 
  height: 80px;
  border: solid black .05px;
  color: white;
  display: block;
}


.footer-content {
  padding: 0 10px;
  max-height: 300px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: black;
  width: 600px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: calc(50% - 300px);
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  
}

.footer-header {
  
  margin-left: 168px;
  text-transform: uppercase;
  margin-top: 10px; 
  /* border: solid lightgray 1px; */
  text-align: center;
  align-items: center;
  justify-content: center;
  display: inline-block;
}

.web-link {
  color: white;
  transition: font-style .5s;
}


.details-btn {
  text-decoration: none;
  float: right;
  color: rgb(233, 227, 227);
  background-color: black;
  height: 35px;
  width: 100px;
  font-size: 30px;
  border: none;
  margin-top: 0px; 
  outline: none;
  margin-right: 40px;
  padding-left: 0px;
}

.details-btn:hover {
  color: white;
  cursor: pointer;
  
}

.more-space {
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-top: 0px;
  margin-top: 0px;
}

@media only screen and (max-width: 1318px) {
  .col3 {
    width: calc(30% - 2px);
    float: right;
    border-left: .5px solid black;
    border-right: .5px solid black;
    max-height: 70%;
    margin-top: 0px;
    height: 1000px;
  }
  .col2 {
    width: 41.3%;
    float: left;
    max-height: 70%;
  }
  .col1 {
    width: 28.5%;
    float: left;
    border-left: .5px solid black;
    border-right: .5px solid black;
  }
  .col4{
    width: calc(71% - 2px);
    float: right;
    border-top: .5px solid black;
    border-right: .5px solid black;
  }
  .pic3{
    height: 90%;
    width: 70%;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
  }
  .caption4{
    padding-bottom: 10%;
    font-size: 11px;
    margin-left: 5%;
    font-weight: 350;
  }
  .pic-container-no-bottom{
    height: 40%;
    border-bottom: none;
  }
}

@media only screen and (max-width: 1245px) {
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: inline-block;
    width: 70%;
    margin-left: 45.5%;
    margin-top: 0px;
  }
  
  .navigate-two {
    margin: 0 auto;
    padding: 0px;
    display: inline-block;
    align-items: baseline;
    
  }

  .pic3{
    height: 90%;
    width: 70%;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
  }

  .caption7{
    padding-bottom: 8.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
}

@media only screen and (max-width: 1125px) {
  .pic3{
    height: 90%;
    width: 80%;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 1000px) {
  .col3 {
    width: calc(30.5% - 2px);
    float: right;
    border-left: .5px solid black;
    border-right: .5px solid black;
    max-height: 70%;
    margin-top: 0px;
  }
  .col2 {
    width: 40.3%;
    float: left;
    max-height: 70%;
  }
  .col1 {
    width: 28.5%;
    float: left;
    border-left: .5px solid black;
    border-right: .5px solid black;
  }
  .col4{
    width: calc(70.4% - 2px);
    float: right;
    border-top: .5px solid black;
    border-right: .5px solid black;
  }
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: inline-block;
    width: 60%;
    margin-left: 35.5%;
    margin-top: 0px;
  }
  .pic3{
    height: 80%;
    width: 80%;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
  }
  .pic6{
    height: 42%;
    width: 52%;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 15%;
  }
}

@media only screen and (max-width: 840px) {
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: inline-block;
    width: relative;
    margin-left: relative;
    margin-top: 0px;
  }
  .col3 {
    width: calc(30.5% - 2px);
    float: right;
    border-left: .5px solid black;
    border-right: .5px solid black;
    max-height: 70%;
    margin-top: 0px;
    height: 50%;
  }
  .caption7{
    padding-bottom: 10.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
}

@media only screen and (max-width: 605px) { 
  .menu-item {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: inline-block;
    width: 100%;
    margin-left: relative;
    margin-top: 0px;
  } 
  
}
@media only screen and (max-width: 540px) { 
  .menu-item {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: block;
    width: 100%;
    margin-left: 10%;
    margin-top: 0px;
  } 
  .header-logo {
    margin: 0 auto;
    display: inline-block;
    padding: 0px;
    width: relative;
    margin-top: 0px;
  }
  img {
    height: 20px;
    float: left;
    padding-top: 5px;
  }
  .navbar {
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    margin: 0 auto;
    padding: 0px;
    height: 30px;
    margin-top: 0px;
    display: flex;
  }
  .nav-item {
    margin: 0 auto;
    width: 50px;
    text-transform: uppercase;
    display: inline-block;
    border-left: solid black 1px;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
    font-size: x-small;
    text-decoration: none;
  }
  .caption7{
    padding-bottom: 15.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  
}
@media only screen and (max-width: 375px) {
  .menu-item {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .col3 {
    width: calc(30.5% - 2px);
    float: right;
    border-left: .5px solid black;
    border-right: .5px solid black;
    max-height: 70%;
    margin-top: 0px;
    height: 450px;
  }
  .caption7{
    padding-bottom: 16.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  html {
    height: 90%;
  }
  .col1 {
    width: 28.5%;
    float: left;
    border-left: .5px solid black;
    border-right: .5px solid black;
    height: 40%;
  }
  .medium-box{
    height: 55px;
    border-bottom: .5px solid black;
  }
}

/* @media only screen and (max-width: 1140px) {
  
  .work1to3 {
    margin: 0 auto;
    width: 60%;
    height: inherit;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .work {
    width: 200px;
    height: 300px;
    margin: 10px 20px 10px 20px;
  }
  .links {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }
  
  .footer-container {
    width: 100%;
  }

} */
/* @media only screen and (max-width: 750px) {
  .links {
    display: block;
  }
  .used-project {
    height: 100%;
    width: 100%;
  }
  .work1to3 {
    margin: 0 auto;
    display: block;
    width: inherit;
    height: inherit;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
  .work-caption {
    width: 100%;
    height: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .work {
    width: 350px;
    height: 525px;
  }
  
} */
/* @media only screen and (max-width: 540px) {
  .links {
    display: block;
  }
  .work1to3 {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
  .work-caption {
    height: 480px;
    width: 89%;
    text-align: center;
    margin: 0;
  }
  .work {
    width: 300px;
    height: 450px;
  }
  
} */
/* @media only screen and (max-width: 400px) {
  .links {
    display: block;
  }
  .work1to3 {
    margin: 0 auto;
    display: block;
    width: inherit;
    height: inherit;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }
  .work {
    width: 300px;
    height: 450px;
  } 
} */

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {  
  .work {
    height: calc(460px - 10%);
    width: calc(315px - 10%);
    text-align: center;
    align-items: center;
    justify-content: center;  
    padding-left: 8px;
    padding-top: 30px;
  }
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 70%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 350px;
    padding-left: 10px;
  }

  .bio {
    width: 275px;
    text-align: left;
    padding-left: 25px;
    padding-top: 30px;
    display: block;
    padding-bottom: 20px;
    font-size: 12px;
  }
  
  .footer-container {
    width: 91%;
  }
  #profile-pic {
    width: 90%;
    height: 77%;
  }
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: block;
    width: 100%;
    margin-left: 10%;
    margin-top: 0px;
  } 
  .header-logo {
    margin: 0 auto;
    display: inline-block;
    padding: 0px;
    width: relative;
    margin-top: 0px;
  }
  img {
    height: 20px;
    float: left;
    padding-top: 5px;
  }
  .navbar {
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    margin: 0 auto;
    padding: 0px;
    height: 30px;
    margin-top: 0px;
    display: flex;
  }
  .nav-item {
    margin: 0 auto;
    width: 50px;
    text-transform: uppercase;
    display: inline-block;
    border-left: solid black 1px;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
    font-size: x-small;
    text-decoration: none;
  }
  .caption7{
    padding-bottom: 19.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  /* Work */
  .work1to3 {
    display: block;
    margin-bottom: 0px;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }


  /* Projects */
  .tiny {
    width: calc(350px - 20%);
    height: calc(410px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }

  .smallest {
    width: calc(350px - 20%);
    height: calc(437px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .smish {
    width: calc(350px - 20%);
    height: calc(490px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .sm {
    width: calc(350px - 20%);
    height: calc(525px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .justoversmed {
    height: calc(437px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: calc(468px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .midjust {
    height: calc(482px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .smed {
    height: calc(437px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .justundermed {
    height: calc(387px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .med {
    height: calc(467px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .medi {
    height: calc(431px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .medium {
    height: calc(438px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    /* padding: 0px; */
    padding-left: 5%;
    /* margin-left: 4.5%; */
    margin-top: 20px;
  }
  .sml {
    height: calc(345px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .sqr {
    height: calc(350px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .ml {
    height: calc(280px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .lg {
    height: calc(233px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .unknown {
    height: calc(229px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .xl {
    height: calc(200px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .xxl {
    height: calc(233px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .xxlarge {
    height: calc(254px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .xxxl {
    height: calc(264px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
  }
  .biggest {
    height: calc(176px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 5%;
    margin-top: 20px;
   
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 26%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
    font-size: 10px;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 416px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }

  .proj-foot-div {
    width: 100%;
  }

  /* Contact Page */
  .singleItem input {
    width: 275px;
  }
  .message {
    width: 275px;
  }
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 100%;
    height: 90%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 10%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
  #used-ftr-content {
    font-size: 10.6px;
  }
}
/* ----------- Galaxy S6 etc ----------- */
@media only screen and (min-device-width: 360px) and (max-device-width: 667px){
  .work {
    height: calc(460px - 10%);
    width: calc(315px - 10%);
    text-align: center;
    align-items: center;
    justify-content: center;  
    padding-left: 10%;
    padding-top: 30px;
  }
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 70%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 350px;
    padding-left: 10px;
  }

  .bio {
    width: 295px;
    text-align: left;
    padding-left: 30px;
    padding-top: 30px;
    display: block;
    padding-bottom: 20px;
    font-size: 12px;
  }
  
  .footer-container {
    width: 91%;
  }
  #profile-pic {
    width: 80%;
    height: 74%;
  }
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: block;
    width: 100%;
    margin-left: 10%;
    margin-top: 0px;
  } 
  .header-logo {
    margin: 0 auto;
    display: inline-block;
    padding: 0px;
    width: relative;
    margin-top: 0px;
  }
  img {
    height: 20px;
    float: left;
    padding-top: 5px;
  }
  .navbar {
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    margin: 0 auto;
    padding: 0px;
    height: 30px;
    margin-top: 0px;
    display: flex;
  }
  .nav-item {
    margin: 0 auto;
    width: 50px;
    text-transform: uppercase;
    display: inline-block;
    border-left: solid black 1px;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
    font-size: x-small;
    text-decoration: none;
  }
  .caption7{
    padding-bottom: 17.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  /* Work */
  .work1to3 {
    display: block;
    margin-bottom: 0px;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }


  /* Projects */
  .tiny {
    width: calc(350px - 20%);
    height: calc(410px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }

  .smallest {
    width: calc(350px - 20%);
    height: calc(437px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .smish {
    width: calc(350px - 20%);
    height: calc(490px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .sm {
    width: calc(350px - 20%);
    height: calc(525px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .justoversmed {
    height: calc(437px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: calc(468px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .midjust {
    height: calc(482px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .smed {
    height: calc(437px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .justundermed {
    height: calc(387px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .med {
    height: calc(467px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .medi {
    height: calc(431px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .medium {
    height: calc(438px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    /* padding: 0px; */
    padding-left: 11%;
    /* margin-left: 4.5%; */
    margin-top: 20px;
  }
  .sml {
    height: calc(345px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .sqr {
    height: calc(350px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .ml {
    height: calc(280px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .lg {
    height: calc(233px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .unknown {
    height: calc(229px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .xl {
    height: calc(200px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .xxl {
    height: calc(233px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .xxlarge {
    height: calc(254px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .xxxl {
    height: calc(264px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
  }
  .biggest {
    height: calc(176px - 20%);
    width: calc(350px - 20%);
    margin: 0px;
    padding-left: 11%;
    margin-top: 20px;
   
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 29%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 416px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }

  .proj-foot-div {
    width: 100%;
  }

  /* Contact Page */
  .singleItem input {
    width: 275px;
  }
  .message {
    width: 275px;
  }
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 100%;
    height: 95%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 10%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .work {
    height: 460px;
    width: 315px;
    text-align: center;
    align-items: center;
    justify-content: center;  
    padding-left: 20px;
    padding-top: 30px;
  }
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 70%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 350px;
    padding-left: 10px;
  }

  .bio {
    width: 350px;
    text-align: left;
    padding-left: 25px;
    padding-top: 30px;
    display: block;
    padding-bottom: 20px;
  }
  
  .footer-container {
    width: 95%;
  }
  #profile-pic {
    width: 90%;
    height: 90%;
}
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: block;
    width: 100%;
    margin-left: 10%;
    margin-top: 0px;
  } 
  .header-logo {
    margin: 0 auto;
    display: inline-block;
    padding: 0px;
    width: relative;
    margin-top: 0px;
  }
  img {
    height: 20px;
    float: left;
    padding-top: 5px;
  }
  .navbar {
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    margin: 0 auto;
    padding: 0px;
    height: 30px;
    margin-top: 0px;
    display: flex;
  }
  .nav-item {
    margin: 0 auto;
    width: 50px;
    text-transform: uppercase;
    display: inline-block;
    border-left: solid black 1px;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
    font-size: x-small;
    text-decoration: none;
  }
  .caption7{
    padding-bottom: 15.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  /* Work */
  .work1to3 {
    display: block;
    margin-bottom: 0px;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }


  /* Projects */

  .smallest {
    
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .smish {
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .sm {
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .justoversmed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: 468px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .midjust {
    height: 482px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .smed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .justundermed {
    height: 387px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .med {
    height: 467px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .medi {
    height: 431px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .medium {
    height: 438px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .sml {
    height: 345px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .sqr {
    height: 350px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .ml {
    height: 280px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .lg {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .unknown {
    height: 229px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .xl {
    height: 200px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .xxl {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .xxlarge {
    height: 254px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .xxxl {
    height: 264px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-left: 3.5%;
    margin-top: 20px;
  }
  .biggest {
    height: 176px;
    width: 350px;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    margin-left: 3.5%;
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 30%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 400px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }

  /* Contact Page */
  .singleItem input {
    width: 300px;
  }
  .message {
    width: 300px;
  }
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 100%;
    height: 100%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 10%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
}
/* ----------- Pixel XL ----------- */
@media only screen and (min-device-width: 411px)and (max-device-width: 667px) {
  .work {
    height: 460px;
    width: 315px;
    text-align: center;
    align-items: center;
    justify-content: center;  
    padding-left: 10%;
    padding-top: 30px;
  }
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 70%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 350px;
    padding-left: 10px;
  }

  .bio {
    width: 350px;
    text-align: left;
    padding-left: 30px;
    padding-top: 30px;
    display: block;
    padding-bottom: 20px;
  }
  
  .footer-container {
    width: 95%;
  }
  #profile-pic {
    width: 90%;
    height: 90%;
}
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: block;
    width: 100%;
    margin-left: 10%;
    margin-top: 0px;
  } 
  .header-logo {
    margin: 0 auto;
    display: inline-block;
    padding: 0px;
    width: relative;
    margin-top: 0px;
  }
  img {
    height: 20px;
    float: left;
    padding-top: 5px;
  }
  .navbar {
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    margin: 0 auto;
    padding: 0px;
    height: 30px;
    margin-top: 0px;
    display: flex;
  }
  .nav-item {
    margin: 0 auto;
    width: 50px;
    text-transform: uppercase;
    display: inline-block;
    border-left: solid black 1px;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
    font-size: x-small;
    text-decoration: none;
  }
  .caption7{
    padding-bottom: 15.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  /* Work */
  .work1to3 {
    display: block;
    margin-bottom: 0px;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }


  /* Projects */

  .smallest {
    
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smish {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sm {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justoversmed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: 468px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .midjust {
    height: 482px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justundermed {
    height: 387px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .med {
    height: 467px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medi {
    height: 431px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medium {
    height: 438px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sml {
    height: 345px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sqr {
    height: 350px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .ml {
    height: 280px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .lg {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .unknown {
    height: 229px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xl {
    height: 200px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxl {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxlarge {
    height: 254px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxxl {
    height: 264px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .biggest {
    height: 176px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 31.5%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 400px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }

  /* Contact Page */
  .singleItem input {
    width: 300px;
  }
  .message {
    width: 300px;
  }
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 90%;
    height: 100%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 16%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
}
/* ----------- iPhone iPhone 6+, 7+ and 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) { 
  .menu-item {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .work {
    height: 460px;
    width: 315px;
    text-align: center;
    align-items: center;
    justify-content: center;  
    padding-left: 10%;
    padding-top: 30px;
  }
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 70%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 350px;
    padding-left: 10px;
  }

  .bio {
    width: 350px;
    text-align: left;
    padding-left: 30px;
    padding-top: 30px;
    display: block;
    padding-bottom: 20px;
  }
  
  .footer-container {
    width: 95%;
  }
  #profile-pic {
    width: 90%;
    height: 90%;
}
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: block;
    width: 100%;
    margin-left: 10%;
    margin-top: 0px;
  } 
  .header-logo {
    margin: 0 auto;
    display: inline-block;
    padding: 0px;
    width: relative;
    margin-top: 0px;
  }
  img {
    height: 20px;
    float: left;
    padding-top: 5px;
  }
  .navbar {
    border-top: solid black 1px;
    border-bottom: solid black 1px;
    margin: 0 auto;
    padding: 0px;
    height: 30px;
    margin-top: 0px;
    display: flex;
  }
  .nav-item {
    margin: 0 auto;
    width: 50px;
    text-transform: uppercase;
    display: inline-block;
    border-left: solid black 1px;
    height: 30px;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
    font-size: x-small;
    text-decoration: none;
  }
  .caption7{
    padding-bottom: 15.5%;
    font-size: 11px;
    margin-left: 25%;
    font-weight: 350;
  }
  /* Work */
  .work1to3 {
    display: block;
    margin-bottom: 0px;
  }
  .work-caption {
    width: 100%;
    text-align: center;
  }


  /* Projects */

  .smallest {
    
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smish {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sm {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justoversmed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: 468px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .midjust {
    height: 482px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justundermed {
    height: 387px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .med {
    height: 467px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medi {
    height: 431px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medium {
    height: 438px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sml {
    height: 345px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sqr {
    height: 350px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .ml {
    height: 280px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .lg {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .unknown {
    height: 229px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xl {
    height: 200px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxl {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxlarge {
    height: 254px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxxl {
    height: 264px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .biggest {
    height: 176px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 31.5%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 400px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }

  /* Contact Page */
  .singleItem input {
    width: 300px;
  }
  .message {
    width: 300px;
  }
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 90%;
    height: 100%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 16%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .work {
    height: 321px;
    width: 220px;
    text-align: center;
    align-items: center;
    justify-content: center;  
    
    padding: 0px 0px 0px 0px;
    padding-top: 30px;
    margin: 0 auto;
    
  }
  .links {
    width: 250px;
  }
  
  .work-caption {
    width: 249px;
  }
  /* Projects */

  .smallest {
    
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smish {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sm {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justoversmed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .premidjust {
    
    height: 468px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .midjust {
    height: 482px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justundermed {
    height: 387px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .med {
    height: 467px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medi {
    height: 431px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medium {
    height: 438px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sml {
    height: 345px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sqr {
    height: 350px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .ml {
    height: 280px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .lg {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .unknown {
    height: 229px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xl {
    height: 200px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxl {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxlarge {
    height: 254px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxxl {
    height: 264px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .biggest {
    height: 176px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
    margin-top: 2px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 31.5%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 400px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }
  
  .details-btn img{
    height: 40px;
    padding-left: 84%;
    padding-top: 3%;
  }

  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 80%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 90%;
    padding-left: 10px;
  }

  .bio {
    width: 500px;
    text-align: left;
    padding-left: 20%;
    padding-top: 5%;
    display: block;
    padding-bottom: 90px;
  }
  #profile-pic {
    width: 87%;
    height: 100%;
  }
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 49%;
    height: 100%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 40%;
}
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
  
  .menu-item {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .navigate {
    margin: 0 auto;
    padding: 0px;
    display: inline-block;
    width: 100%;
    margin-left: relative;
    margin-top: 0px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .work {
    height: 321px;
    width: 220px;
    text-align: center;
    align-items: center;
    justify-content: center;  
    /* padding-left: 20%; */
    padding: 0px 0px 0px 0px;
    padding-top: 30px;
    margin: 0 auto;
    
  }
  .links {
    width: 250px;
  }
  
  .work-caption {
    width: 249px;
  }
  /* Projects */

  .smallest {
    
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smish {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sm {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justoversmed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: 468px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .midjust {
    height: 482px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justundermed {
    height: 387px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .med {
    height: 467px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medi {
    height: 431px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medium {
    height: 438px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sml {
    height: 345px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sqr {
    height: 350px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .ml {
    height: 280px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .lg {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .unknown {
    height: 229px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xl {
    height: 200px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxl {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxlarge {
    height: 254px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxxl {
    height: 264px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .biggest {
    height: 176px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
    margin-top: 2px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 31.5%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 400px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }
  /* .work1to3 {
    display: block;
    margin-bottom: 0px;
  } */
  .details-btn img{
    height: 40px;
    padding-left: 84%;
    padding-top: 3%;
  }

  /* About Page */
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 80%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 90%;
    padding-left: 10px;
  }
  .bio {
    width: 600px;
    text-align: left;
    padding-left: 22%;
    padding-top: 5%;
    display: block;
    padding-bottom: 90px;
    font-size: 20px;
}
  #profile-pic {
    width: 87%;
    height: 100%;
  }

  /* Contact Page */
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 39%;
    height: 100%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 44%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
  .message {
    width: 437px;
  }

}

@media only screen and (min-device-width: 1024px) and (max-width: 1040px) {
  .work {
    height: 321px;
    width: 220px;
    text-align: center;
    align-items: center;
    justify-content: center;  
    /* padding-left: 20%; */
    padding: 0px 0px 0px 0px;
    padding-top: 30px;
    margin: 0 auto;
    
  }
  .links {
    width: 250px;
  }
  
  .work-caption {
    width: 249px;
  }
  /* Projects */

  .smallest {
    
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smish {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sm {
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justoversmed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .premidjust {
    /* display: block; */
    height: 468px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .midjust {
    height: 482px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .smed {
    height: 437px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .justundermed {
    height: 387px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .med {
    height: 467px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medi {
    height: 431px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .medium {
    height: 438px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sml {
    height: 345px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .sqr {
    height: 350px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .ml {
    height: 280px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .lg {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .unknown {
    height: 229px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xl {
    height: 200px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxl {
    height: 233px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxlarge {
    height: 254px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .xxxl {
    height: 264px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  .biggest {
    height: 176px;
    width: 350px;
    margin: 0px;
    padding-left: 7.7%;
    margin-top: 20px;
  }
  
  .box1 {
    width: 100%;
  }

  /* Project Footer */
  .footer-header {
    margin-left: 0px;
    display: block;
    font-size: 13px;
    margin-top: 2px;
  }
  .details-btn{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    float: none;
    height: 25px;
    padding-left: 31.5%;
  }
  .footer-content {
    width: 90%;
    margin-left: 3%;
  }
  .closed {
    height: 110px;
  }
  .opened {
    height: 400px;
  }
  #used-ftr-content {
    font-size: 11.6px;
  }
  /* .work1to3 {
    display: block;
    margin-bottom: 0px;
  } */
  .details-btn img{
    height: 40px;
    padding-left: 84%;
    padding-top: 3%;
  }

  /* About Page */
  .about-page {
    text-align: center;
    display: block;
    width: 95%;
    justify-content: left;
}
  .about-pic {
    width: 80%;
    padding-right: 20px;
    width: 100%;
    /* width: 550px; */
    padding-right: 20px;
    height: 90%;
    padding-left: 10px;
  }
  .bio {
    width: 600px;
    text-align: left;
    padding-left: 22%;
    padding-top: 5%;
    display: block;
    padding-bottom: 90px;
    font-size: 20px;
}
  #profile-pic {
    width: 87%;
    height: 100%;
  }

  /* Contact Page */
  .ball-image {
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    height: 240px;
    width: 100%;
    float: left;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .blueball {
    width: 39%;
    height: 100%;
    margin-bottom: 70px;
    padding-bottom: 50px;
    margin-right: 200px;
    padding-left: 44%;
  }
  .container {
    display: block;
    width: 100%;
  }
  .wholecontact {
    display: block;
  }
  .message {
    width: 437px;
  }

}

